import { useEffect } from "react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { usePostHog } from "posthog-js/react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";

export function Router() {
	const fp = useVisitorData({ timeout: 30000, extendedResult: true });
	const posthog = usePostHog();

	useEffect(() => {
		if (!fp.isLoading && fp.data) {
			posthog.identify(fp.data.visitorId, { fp: fp.data });
		}
	}, [fp.data, fp.isLoading, fp.error]);

	return (
		<RouterProvider
			router={createBrowserRouter([
				{
					path: "",
					async lazy() {
						const { Layout } = await import("./Components/Shared/Layout");
						return { Component: Layout };
					},
					children: [
						{
							path: "",
							async lazy() {
								const { HomePage } = await import("./Components/Pages/HomePage");
								return { Component: HomePage };
							}
						},
						{
							path: "solutions",
							children: [
								{
									path: "deliverable-creation",
									async lazy() {
										const { DeliverableCreationPage } = await import(
											"./Components/Pages/Solutions/DeliverableCreationPage"
										);
										return { Component: DeliverableCreationPage };
									}
								},
								{
									path: "information-retrieval",
									async lazy() {
										const { InformationRetrievalPage } = await import(
											"./Components/Pages/Solutions/InformationRetrievalPage"
										);
										return { Component: InformationRetrievalPage };
									}
								},
								{
									path: "investment-banking",
									async lazy() {
										const { InvestmentBankingPage } = await import(
											"./Components/Pages/Solutions/InvestmentBankingPage"
										);
										return { Component: InvestmentBankingPage };
									}
								},
								{
									path: "private-equity",
									async lazy() {
										const { PrivateEquityPage } = await import("./Components/Pages/Solutions/PrivateEquityPage");
										return { Component: PrivateEquityPage };
									}
								},
								{
									path: "equity-research",
									async lazy() {
										const { EquityResearchPage } = await import("./Components/Pages/Solutions/EquityResearchPage");
										return { Component: EquityResearchPage };
									}
								}
							]
						},
						{
							path: "security",
							children: [
								{
									path: "how-we-secure-your-data",
									async lazy() {
										const { HowWeSecureYourDataPage } = await import(
											"./Components/Pages/Security/HowWeSecureYourDataPage"
										);
										return { Component: HowWeSecureYourDataPage };
									}
								},
								{
									path: "security-and-privacy-policy",
									async lazy() {
										const { SecurityPolicyPage } = await import("./Components/Pages/Security/SecurityPolicyPage");
										return { Component: SecurityPolicyPage };
									}
								},
								{
									path: "governance",
									async lazy() {
										const { DataGovernancePage } = await import("./Components/Pages/Security/DataGovernancePage");
										return { Component: DataGovernancePage };
									}
								}
							]
						},
						{
							path: "responsible-ai",
							children: [
								{
									path: "overview",
									async lazy() {
										const { ResponsibleAIOverviewPage } = await import(
											"./Components/Pages/ResponsibleAI/ResponsibleAIOverviewPage"
										);
										return { Component: ResponsibleAIOverviewPage };
									}
								},
								{
									path: "answers-you-can-trust",
									async lazy() {
										const { AnswersYouCanTrustPage } = await import(
											"./Components/Pages/ResponsibleAI/AnswersYouCanTrustPage"
										);
										return { Component: AnswersYouCanTrustPage };
									}
								},
								{
									path: "data-training-and-privacy",
									async lazy() {
										const { DataTrainingAndPrivacyPage } = await import(
											"./Components/Pages/ResponsibleAI/DataTrainingAndPrivacyPage"
										);
										return { Component: DataTrainingAndPrivacyPage };
									}
								},
								{
									path: "principles-and-approach",
									async lazy() {
										const { PrinciplesAndApproachPage } = await import(
											"./Components/Pages/ResponsibleAI/PrinciplesAndApproachPage"
										);
										return { Component: PrinciplesAndApproachPage };
									}
								}
							]
						},
						{
							path: "get-started",
							async lazy() {
								const { GetStartedPage } = await import("./Components/Pages/GetStartedPage");
								return { Component: GetStartedPage };
							}
						},
						{
							path: "*",
							element: (
								<Navigate
									to="/"
									replace
								/>
							)
						}
					]
				}
			])}
		/>
	);
}
